@tailwind base;
@tailwind components;
@tailwind utilities;
body {
    font-size: 12px;
    /* @apply text-gray-600 font-medium */
}

@font-face {
    font-family: "Inter UI";
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url("assets/fonts/Inter-UI-Regular.woff2") format("woff2"), url("assets/fonts/Inter-UI-Regular.woff") format("woff");
}

@font-face {
    font-family: "Inter UI";
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url("assets/fonts/Inter-UI-Medium.woff2") format("woff2"), url("assets/fonts/Inter-UI-Medium.woff") format("woff");
}

@font-face {
    font-family: "Inter UI";
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: url("assets/fonts/Inter-UI-SemiBold.woff2") format("woff2"), url("assets/fonts/Inter-UI-SemiBold.woff") format("woff");
}

@font-face {
    font-family: "Inter UI";
    font-style: normal;
    font-weight: 800;
    font-display: swap;
    src: url("assets/fonts/Inter-UI-ExtraBold.woff2") format("woff2"), url("assets/fonts/Inter-UI-ExtraBold.woff") format("woff");
}

@font-face {
    font-family: 'Inter Thin BETA';
    font-style: normal;
    font-weight: normal;
    src: local('Inter Thin BETA'), url('assets/fonts/Inter-Thin-BETA.woff') format('woff');
}
@font-face {
    font-family: 'Inter Light BETA';
    font-style: normal;
    font-weight: normal;
    src: local('Inter Light BETA'), url('assets/fonts/Inter-Light-BETA.woff') format('woff');
}
                

.ProseMirror {
    font-family: 'Inter UI', sans-serif !important;
}

/* Loader */
.spinner_3 {
    width:50px;
    height:50px;
    border-radius:50%;
    background:conic-gradient(#0000 10%,#4338CA);
    mask: radial-gradient(farthest-side,#0000 calc(100% - 8px),#000 0);
    -webkit-mask:radial-gradient(farthest-side,#0000 calc(100% - 8px),#000 0);
    animation:s3 1s infinite linear;
  }
  @keyframes s3 {to{transform: rotate(1turn)}}

.member-grid-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 0.2fr;
    min-height: 30px;
    align-items: center;
    background-color: #fafafa;
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 10px;
}
@media only screen and (max-width: 600px) {
    .member-grid-container {
        grid-template-columns: 1fr  1fr 0.2fr;
    }
}

/* width */
*::-webkit-scrollbar {
    width: 16px;
    height: 16px;
  }

  /* Track */
  *::-webkit-scrollbar-track {
    border-radius: 100vh;
    background: #edf2f7;
  }

  /* Handle */
  *::-webkit-scrollbar-thumb {
    background: #cbd5e0;
    border-radius: 100vh;
    border: 3px solid #edf2f7;
  }

  /* Handle on hover */
  *::-webkit-scrollbar-thumb:hover {
    background: #a0aec0;
  }

  .features-grid-container {
	display: grid;
	grid-gap: 1rem;
	grid-template-columns: 1fr;
    }
@media screen and (min-width: 600px) {
    .features-grid-container {
        grid-template-columns: repeat(2, 1fr);
    }
}
@media screen and (min-width: 1200px) {
    .features-grid-container {
        grid-template-columns: repeat(3, 1fr);
    }
}
::selection {
    background-color: #4338CA;
    color: #fff; }
  